import React, { useState } from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Tab, Nav} from 'react-bootstrap';
import axios from 'axios';
import { url } from '../../../../handeApisMethods/a-MainVariables';

const ChartBarApex = loadable(() =>
	pMinDelay(import("./ChartBarApex"), 1000)
);
const ChartBarApex2 = loadable(() =>
	pMinDelay(import("./ChartBarApex2"), 1000)
);
const ChartBarApex3 = loadable(() =>
	pMinDelay(import("./ChartBarApex3"), 1000)
);

const ReservationStats = ()=> {
	const [title, setTitle] = useState("")
	const [msg, setMsg] = useState("")

	const handleChangeTitle = (e) => {
		setTitle(e.target.value)
	}
	const handleChangeMsg = (e) => {
		setMsg(e.target.value)
	}

	function push(title, msg) {
		//axios call
		const postData = {
			title, msg
		};
		let response = axios.post(
			`${url}/api/admin/push-notification-all`,
			postData
		);
		response.then(() => {
			window.location.reload()
		})
	}
	return(
		<>
			<Tab.Container defaultActiveKey="Monthly">
				<div className="card">
					<div className="card-header border-0 flex-wrap">
						<h4 className="fs-20">Push notification</h4>
					</div>
					<div className="card-body pb-0">
						<div className="w-100 form-group">
							<label for="names">Notification Title*</label>
							<input
								type="text"
								className="form-control"
								id="names"
								placeholder="Hotel Name"
								value={title}
								onChange={handleChangeTitle} />
						</div>

					</div>
					<div className="card-body pb-0">
						<div className="w-100 form-group">
							<label for="names">Notification Message*</label>
							<input
								type="text"
								className="form-control"
								id="names"
								placeholder="Hotel Name"
								value={msg}
								onChange={handleChangeMsg} />
						</div>

					</div>
					<div className="card-body pb-0">
						<button onClick={() => push(title, msg)} className='btn btn-primary w-100 mb-4'>Send</button>
					</div>
				</div>
			</Tab.Container>
		</>
	)
}
export default ReservationStats;